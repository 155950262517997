import Mock from 'mockjs'
import homeMock from '../api/mockServe/home'
// import user from './user'
import permission from './mockServe/permission'
//import login from './mockServe/login'

// 定义mock拦截
Mock.mock('/admin/home/getData',homeMock)

// 用户管理:增删查改
// Mock.mock('/admin/user/get',user.getUserList)
// Mock.mock('/admin/user/create','post',user.createUser)
// Mock.mock('/admin/user/update','post',user.updateUser)
// Mock.mock('/admin/user/del','post',user.deleteUser)

// 登录权限
Mock.mock('/admin/permission/getMenu','post',permission.getMenu)

// 登录
//Mock.mock(/admin\/login/, 'post', login.login)